(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("fetch"), require("window"));
	else if(typeof define === 'function' && define.amd)
		define(["fetch", "window"], factory);
	else if(typeof exports === 'object')
		exports["auth"] = factory(require("fetch"), require("window"));
	else
		root["solid"] = root["solid"] || {}, root["solid"]["auth"] = factory(root["fetch"], root["window"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__27__) {
return 